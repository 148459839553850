/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { API, Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import {
  listDeployments,
  getDeployment,
  getMachineTelemetryConsumption,
  getMachineTelemetryConsumptionCSV,
  getBrandConsumptions,
  getPermissions,
  listAssetsNotDeleted,
  uploadAssetsFromPackage,
  getTelemetryObject,
  createSerialDeploy,
  createRegexDeploy,
  createMarketDeploy,
  createGlobalDeploy,
  deleteDeploy,
  getProjects,
  deleteAsset,
  getLifeCycleObject,
  getLifeCycleObjectPaginated,
  updateAssetDescription,
  getSandboxObject,
  getSandboxObjectCSV,
  getAssetTypes,
  getDeploymentFileTypes,
  getAsset,
  getCognitoUsersGroupsCSV,
  getActionsBetweenDates,
  getActionsBetweenDatesWithUser,
  getActionStatistics,
  getNotifications,
  getConfig,
  getUnreadNotificationsCount,
  getUnreadNotifications,
  createNotification,
  markAsReadMutation,
  deleteNotification,
  getBrandConsumptionsCSV,
  getTelemetryObjectCSV,
  getLifeCycleObjectCSV,
  updateConfig,
  getBundle,
  getBundles,
  getBundleFileTypes,
  deleteBundle,
  createBundle,
  updateBundle,
  getSRFfunctions,
  getSRFBackends,
  executeSRF,
  getSRFToken,
  getSRFRequest,
  getSRFRequests,
  cancelSRF,
  getCertificateQuota,
  getCertificateStatus,
  deleteNotificationsByTitle,
  getBundleDeployments,
  deleteWave,
  listPaginatedWaves,
  getWaveById,
  updateWave,
  onboardingDevice,
  subscribeToTopic,
  getIotMessages,
  getPlatformHealth,
  getServicesHealthHistory,
  getServicesHealthHistoryByBrands,
  getTestsExecutions,
  triggerTestsExecutions,
  triggerHealthCalculation,
  getDevicesPermissionsByProject,
  createDevicePermission,
  deleteDevicePermission,
  deleteAllDevicesPermissions,
  getDeviceConnectivityStatus,
  getDeviceConnectivityStatusHistory,
  getShadowState,
  getNewUsersBetweenDates,
} from '../config/graphql';
import S3Service from './s3Service';
import mapRoleToPermissions from '../config/permissionsMapper';

export default class GraphqlService {
  static async getProjects() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getProjects,
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getProjects.__typename === 'GetProjectsSuccess') {
        return postData.data.getProjects.projects;
      }
      return Promise.reject(new Error(postData.data.getProjects.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getCognitoUsersGroupsCSV() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getCognitoUsersGroupsCSV,
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getCognitoUsersGroupsCSV.__typename === 'GetCognitoUsersGroupsCSVSuccess') {
        return postData.data.getCognitoUsersGroupsCSV.csvData;
      }
      return Promise.reject(new Error(postData.data.getCognitoUsersGroupsCSV.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getActionsBetweenDates({
    from, to, userId, page, limit,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const variables = userId ? {
        from, to, user: userId, page, limit,
      } : {
        from, to, page, limit,
      };
      const query = userId ? getActionsBetweenDatesWithUser : getActionsBetweenDates;
      const postData = await API.graphql({
        query,
        variables,
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getActionsBetweenDates.__typename === 'GetActionsSuccess') {
        return postData.data.getActionsBetweenDates;
      }
      return Promise.reject(new Error(postData.data.getActionsBetweenDates.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getActionStatistics(from, to, projectId) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const graphQlResult = await API.graphql({
        variables: { from, to, projectId },
        query: getActionStatistics,
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (graphQlResult.errors || !graphQlResult.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (graphQlResult.data.getActionStatistics.__typename === 'GetActionStatisticsSuccess') {
        return graphQlResult.data.getActionStatistics.data;
      }
      return Promise.reject(new Error(graphQlResult.data.getActionStatistics.message));
    } catch (data) {
      console.error('Error getActionStatistics: ', data);
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async getPermissions(projectId, permissions) {
    const user = await Auth.currentAuthenticatedUser();
    let rawPermissions = permissions;
    if (!permissions) {
      // calculate the rawPermissions with the token
      const decodedToken = jwt_decode(user.signInUserSession.accessToken.jwtToken);
      if (decodedToken) {
        const rawGroups = decodedToken['cognito:groups'] ? decodedToken['cognito:groups'] : decodedToken.groups;
        // eslint-disable-next-line no-param-reassign
        rawPermissions = mapRoleToPermissions(rawGroups);
      }
    }

    const postData = await API.graphql({
      query: getPermissions,
      variables: {
        projectId,
        rawPermissions,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });
    return postData.data.getFrontendPermissions.permissions;
  }

  static async listAssetsNotDeleted({
    projectId, page, pageSize, sortDirection, type, assetDescriptionValue, assetDescriptionKey,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: listAssetsNotDeleted,
        variables: {
          projectId, page, pageSize, sortDirection, type, assetDescriptionValue, assetDescriptionKey,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.listAssetsNotDeleted.__typename === 'ListAssetsNotDeletedSuccess') {
        return postData.data.listAssetsNotDeleted;
      }
      return Promise.reject(new Error(postData.data.listAssetsNotDeleted.message));
    } catch (data) {
      console.error('Error: ', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getAsset({
    projectId, fileId,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await API.graphql({
        query: getAsset,
        variables: {
          projectId, fileId,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!response.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (response.data.getAsset.__typename === 'GetAssetSuccess') {
        return response.data.getAsset;
      }
      return Promise.reject(new Error(response.data.getAsset.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error in getAsset: ${data.errors[0].message}`));
    }
  }

  static async getAssetTypes({
    projectId,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const graphqlData = await API.graphql({
        query: getAssetTypes,
        variables: {
          projectId,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!graphqlData.data) {
        return Promise.reject(new Error('Internal Server Error in GetAssetTypesSuccess'));
      }
      if (graphqlData.data.getAssetTypes.__typename === 'GetAssetTypesSuccess') {
        return graphqlData.data?.getAssetTypes?.response;
      }
      return Promise.reject(new Error(graphqlData.data.getAssetTypes.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getDeploymentFileTypes({
    projectId,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const graphqlData = await API.graphql({
        query: getDeploymentFileTypes,
        variables: {
          projectId,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!graphqlData.data) {
        return Promise.reject(new Error('Internal Server Error in GetDeploymentFileTypesSuccess'));
      }
      if (graphqlData.data.getDeploymentFileTypes.__typename === 'GetDeploymentFileTypesSuccess') {
        return graphqlData.data?.getDeploymentFileTypes?.response;
      }
      return Promise.reject(new Error(graphqlData.data.getDeploymentFileTypes.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async listDeployments({
    projectId,
    page,
    pageSize,
    sortDirection,
    fileType,
    deleted,
    deployDescriptionKey,
    deployDescriptionValue,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: listDeployments,
        variables: {
          projectId, page, pageSize, sortDirection, fileType, deleted, deployDescriptionKey, deployDescriptionValue,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.listDeployments.__typename === 'ListDeploymentsSuccess') {
        return postData.data.listDeployments;
      }
      return Promise.reject(new Error(postData.data.listDeployments.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getDeployment({
    projectId, deploymentID,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getDeployment,
        variables: {
          projectId,
          deploymentID,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getDeployment.__typename === 'GetDeploymentSuccess') {
        return postData.data.getDeployment.result;
      }
      return Promise.reject(new Error(postData.data.getBundle.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async listBundles(
    {
      projectId,
      sortDirection,
      fileType,
      page,
      pageSize,
      deleted,
      releaseDescriptionValue,
      releaseDescriptionKey,
    },
  ) {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const postData = await API.graphql({
        query: getBundles,
        variables: {
          projectId,
          sortDirection,
          fileType,
          page,
          pageSize,
          deleted,
          releaseDescriptionValue,
          releaseDescriptionKey,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getBundles.__typename === 'GetBundlesSuccess') {
        return postData.data.getBundles;
      }
      return Promise.reject(new Error(postData.data.getBundles.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getBundle({
    projectId, releaseID,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getBundle,
        variables: {
          projectId,
          releaseID,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getBundle.__typename === 'GetBundleSuccess') {
        return postData.data.getBundle.result;
      }
      return Promise.reject(new Error(postData.data.getBundle.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getBundleDeployments({
    projectId, releaseID,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getBundleDeployments,
        variables: {
          projectId,
          releaseID,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getBundleDeployments.__typename === 'GetBundleDeploymentsSuccess') {
        return postData.data.getBundleDeployments.result;
      }
      return Promise.reject(new Error(postData.data.getBundleDeployments.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getBundleFileTypes({
    projectId,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getBundleFileTypes,
        variables: {
          projectId,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getBundleFileTypes.__typename === 'GetBundleFileTypesSuccess') {
        return postData.data.getBundleFileTypes;
      }
      return Promise.reject(new Error(postData.data.getBundleFileTypes.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getMachineTelemetryConsumption(
    projectId, serialNumber, dateIni, dateEnd, limit, offset,
  ) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getMachineTelemetryConsumption,
        variables: {
          projectId,
          serialNumber,
          dateIni,
          dateEnd,
          limit,
          offset,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getMachineTelemetryConsumption.__typename === 'GetMachineTelemetryConsumptionSuccess') {
        return postData.data.getMachineTelemetryConsumption;
      }
      const errorMessage = postData.data?.getMachineTelemetryConsumption.message || `${postData.data?.getMachineTelemetryConsumption?.statusCode}: ${postData.data.getMachineTelemetryConsumption?.__typename}`;
      return Promise.reject(new Error(errorMessage));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async createBundle(
    projectId, release,
  ) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: createBundle,
        variables: {
          projectId, release,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.createBundle.__typename === 'CreateBundleSuccess') {
        return postData.data.createBundle.result;
      }
      return Promise.reject(new Error(postData.data.createBundle.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async updateBundle(projectId, releaseID, title, description) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: updateBundle,
        variables: {
          projectId, releaseID, title, description,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      console.log('postData updateBundle', postData);
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.updateBundle.__typename === 'UpdateBundleSuccess') {
        return postData.data.updateBundle.result;
      }
      return Promise.reject(new Error(postData.data.updateBundle.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getBrandConsumptions(
    projectId, filters, dateIni, dateEnd, limit, offset, searchByProcessedOnUtc,
  ) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getBrandConsumptions,
        variables: {
          projectId,
          filters,
          dateIni,
          dateEnd,
          limit,
          offset,
          searchByProcessedOnUtc,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getBrandConsumptions.__typename === 'GetBrandConsumptionsSuccess') {
        return postData.data.getBrandConsumptions;
      }
      return Promise.reject(new Error(postData.data.getBrandConsumptions.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getBrandConsumptionsCSV(
    projectId, filters, dateIni, dateEnd,
  ) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getBrandConsumptionsCSV,
        variables: {
          projectId,
          filters,
          dateIni,
          dateEnd,
          user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
          jwtToken: userAuth.signInUserSession.accessToken.jwtToken,
        },
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getBrandConsumptionsCSV.__typename === 'GetBrandConsumptionsCSVSuccess') {
        return postData.data.getBrandConsumptionsCSV;
      }
      return Promise.reject(new Error(postData.data.getBrandConsumptionsCSV.message));
    } catch (data) {
      console.error('error getBrandConsumptionsCSV', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors}`));
    }
  }

  static async getMachineTelemetryConsumptionCSV(
    projectId, serialNumber, dateIni, dateEnd,
  ) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getMachineTelemetryConsumptionCSV,
        variables: {
          projectId,
          serialNumber,
          dateIni,
          dateEnd,
          user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
          jwtToken: userAuth.signInUserSession.accessToken.jwtToken,
        },
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getMachineTelemetryConsumptionCSV.__typename === 'GetMachineTelemetryConsumptionCSVSuccess') {
        return postData.data.getMachineTelemetryConsumptionCSV;
      }
      return Promise.reject(new Error(`${postData.data.getMachineTelemetryConsumptionCSV.statusCode}: ${postData.data.getMachineTelemetryConsumptionCSV.__typename}`));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async uploadAssetsFromPackage(projectId, file) {
    // upload the zip file to the ALM S3 (alm-graphql-files-bucket-TARGET-REGION, configured in AWSExports) to be able to get it from ALM BE
    const success = await S3Service.uploadFile(file);
    if (!success) {
      return Promise.reject(new Error('Could not upload file to S3'));
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      const filePath = file.path;
      const postData = await API.graphql({
        query: uploadAssetsFromPackage,
        variables: {
          projectId,
          filePath,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        await S3Service.deleteFile(filePath);
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.uploadAssetsFromPackage.__typename === 'UploadAssetsFromPackageSuccess') {
        await S3Service.deleteFile(filePath);
        return postData.data.uploadAssetsFromPackage.list;
      }
      return Promise.reject(new Error(postData.data.uploadAssetsFromPackage.message));
    } catch (data) {
      await S3Service.deleteFile(file.path);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getTelemetryObject(
    projectId, object, queryParams, filters, dateIni, dateEnd, limit, offset, datesRange, searchByProcessedOnUtc,
  ) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getTelemetryObject,
        variables: {
          projectId,
          object,
          queryParams,
          filters,
          dateIni,
          dateEnd,
          limit,
          offset,
          datesRange,
          searchByProcessedOnUtc,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getTelemetryObject.__typename === 'GetTelemetryObjectSuccess') {
        return postData.data.getTelemetryObject;
      }
      return Promise.reject(new Error(postData.data.getTelemetryObject.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getTelemetryObjectCSV(
    projectId, object, queryParams, filters, dateIni, dateEnd, datesRange,
  ) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getTelemetryObjectCSV,
        variables: {
          projectId,
          object,
          queryParams,
          filters,
          dateIni,
          dateEnd,
          datesRange,
          user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
          jwtToken: userAuth.signInUserSession.accessToken.jwtToken,
        },
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getTelemetryObjectCSV.__typename === 'GetTelemetryObjectCSVSuccess') {
        return postData.data.getTelemetryObjectCSV;
      }
      return Promise.reject(new Error(postData.data.getTelemetryObjectCSV.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getLifeCycleObject(
    projectId, object, queryParams, filters, dateIni, dateEnd, paginationToken,
  ) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getLifeCycleObject,
        variables: {
          projectId,
          object,
          queryParams,
          filters,
          dateIni,
          dateEnd,
          paginationToken,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getLifeCycleObject.__typename === 'GetLifeCycleObjectSuccess') {
        return postData.data.getLifeCycleObject;
      }
      return Promise.reject(new Error(postData.data.getLifeCycleObject.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getLifeCycleObjectPaginated(
    projectId, object, queryParams, filters, dateIni, dateEnd, page, pageSize,
  ) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getLifeCycleObjectPaginated,
        variables: {
          projectId,
          object,
          queryParams,
          filters,
          dateIni,
          dateEnd,
          page,
          pageSize,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getLifeCycleObjectPaginated.__typename === 'GetLifeCycleObjectPaginatedSuccess') {
        return postData.data.getLifeCycleObjectPaginated;
      }
      return Promise.reject(new Error(postData.data.getLifeCycleObjectPaginated.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getSRFfunctions({ projectId }) {
    const user = await Auth.currentAuthenticatedUser();
    const postData = await API.graphql({
      query: getSRFfunctions,
      variables: {
        projectId,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });
    if (!postData.data) {
      return Promise.reject(new Error('Internal Server Error'));
    }
    if (postData.data.getSRFfunctions.__typename === 'GetSRFfunctionsSuccess') {
      return postData.data?.getSRFfunctions;
    }
    return Promise.reject(new Error(postData.data.getSRFfunctions.message));
  }

  static async getSRFBackends({ projectId }) {
    const user = await Auth.currentAuthenticatedUser();
    const postData = await API.graphql({
      query: getSRFBackends,
      variables: {
        projectId,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });
    if (!postData.data) {
      return Promise.reject(new Error('Internal Server Error'));
    }
    if (postData.data.getSRFBackends.__typename === 'GetSRFBackendsSuccess') {
      return postData.data?.getSRFBackends?.result;
    }
    return Promise.reject(new Error(postData.data.getSRFBackends.message));
  }

  static async executeSRF(
    projectId, machineId, functionName, securityMode, jwtToken,
  ) {
    const user = await Auth.currentAuthenticatedUser();
    const postData = await API.graphql({
      query: executeSRF,
      variables: {
        projectId,
        machineId,
        functionName,
        securityMode,
        jwtToken,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });
    if (!postData.data) {
      return Promise.reject(new Error('Internal Server Error'));
    }
    if (postData.data.executeSRF.__typename === 'ExecuteSRFSuccess') {
      return postData.data?.executeSRF;
    }
    return Promise.reject(new Error(postData.data.executeSRF.message));
  }

  static async cancelSRF(
    projectId, reqId,
  ) {
    const user = await Auth.currentAuthenticatedUser();
    const postData = await API.graphql({
      query: cancelSRF,
      variables: {
        projectId,
        reqId,
      },
      authToken: user.signInUserSession.accessToken.jwtToken,
    });
    console.log('postData', JSON.stringify(postData));
    if (!postData.data) {
      return Promise.reject(new Error('Internal Server Error'));
    }
    if (postData.data.cancelSRF.__typename === 'CancelSRFSuccess') {
      return postData.data?.cancelSRF?.result;
    }
    return Promise.reject(new Error(postData.data.cancelSRF.message));
  }

  static async getLifeCycleObjectCSV(
    projectId, object, queryParams, filters, dateIni, dateEnd, datesRange,
  ) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getLifeCycleObjectCSV,
        variables: {
          projectId,
          object,
          queryParams,
          filters,
          dateIni,
          dateEnd,
          datesRange,
          user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
          jwtToken: userAuth.signInUserSession.accessToken.jwtToken,
        },
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getLifeCycleObjectCSV.__typename === 'GetLifeCycleObjectCSVSuccess') {
        return postData.data?.getLifeCycleObjectCSV;
      }
      return Promise.reject(new Error(postData.data.getLifeCycleObjectCSV.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getSandboxObject(
    projectId, object, queryParams, filters, dateIni, dateEnd, datesRange, paginationToken,
  ) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getSandboxObject,
        variables: {
          projectId,
          object,
          queryParams,
          filters,
          dateIni,
          dateEnd,
          datesRange,
          paginationToken,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getSandboxObject.__typename === 'GetSandboxObjectSuccess') {
        return postData.data.getSandboxObject;
      }
      return Promise.reject(new Error(postData.data.getSandboxObject.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getSandboxObjectCSV(
    projectId, object, queryParams, filters, dateIni, dateEnd, datesRange,
  ) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getSandboxObjectCSV,
        variables: {
          projectId,
          object,
          queryParams,
          filters,
          dateIni,
          dateEnd,
          datesRange,
          user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
          jwtToken: userAuth.signInUserSession.accessToken.jwtToken,
        },
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });
      if (!postData?.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getSandboxObjectCSV.__typename === 'GetSandboxObjectCSVSuccess') {
        return postData.data.getSandboxObjectCSV;
      }
      return Promise.reject(new Error(postData.data.getSandboxObjectCSV?.message));
    } catch (data) {
      console.error(data);
      return Promise.reject(new Error(`Internal Server Error: ${data?.errors}`));
    }
  }

  static async createSerialDeploy(projectId, fileIDS, releaseID, type, value, comments, deployRegEx) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();

      const variables = {
        projectId,
        fileIDS: fileIDS.length > 0 ? fileIDS : undefined,
        releaseID,
        type,
        value,
        comments,
        deployRegEx,
        user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
      };
      console.log('variables', variables);
      const postData = await API.graphql({
        query: createSerialDeploy,
        variables,
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });
      if (postData.data.createSerialDeploy.__typename === 'DeploymentOutputSuccess') {
        if (postData.data.createSerialDeploy?.statusCode) {
          return Promise.reject(postData.data.createSerialDeploy.message);
        }
        return postData.data.createSerialDeploy.deployResult;
      }
      return Promise.reject(new Error(postData.data.createSerialDeploy.message));
    } catch (data) {
      return Promise.reject(new Error('Internal Server Error'));
    }
  }

  static async createMarketDeploy(projectId, fileIDS, releaseID, type, value, comments, deployRegEx) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();

      const variables = {
        projectId,
        fileIDS: fileIDS.length > 0 ? fileIDS : undefined,
        releaseID,
        type,
        value,
        comments,
        deployRegEx,
        user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
      };
      console.log('variables', variables);
      const postData = await API.graphql({
        query: createMarketDeploy,
        variables,
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });
      if (postData.data.createMarketDeploy.__typename === 'DeploymentOutputSuccess') {
        if (postData.data.createMarketDeploy?.statusCode) {
          return Promise.reject(postData.data.createMarketDeploy.message);
        }
        return postData.data.createMarketDeploy.deployResult;
      }
      return Promise.reject(new Error(postData.data.createMarketDeploy.message));
    } catch (data) {
      return Promise.reject(new Error('Internal Server Error'));
    }
  }

  static async createRegexDeploy(projectId, fileIDS, releaseID, type, value, comments, deployRegEx) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();

      const variables = {
        projectId,
        fileIDS: fileIDS.length > 0 ? fileIDS : undefined,
        releaseID,
        type,
        value,
        comments,
        deployRegEx,
        user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
      };
      console.log('variables', variables);
      const postData = await API.graphql({
        query: createRegexDeploy,
        variables,
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });
      if (postData.data.createRegexDeploy.__typename === 'DeploymentOutputSuccess') {
        if (postData.data.createRegexDeploy?.statusCode) {
          return Promise.reject(postData.data.createRegexDeploy.message);
        }
        return postData.data.createRegexDeploy.deployResult;
      }
      return Promise.reject(new Error(postData.data.createRegexDeploy.message));
    } catch (data) {
      return Promise.reject(new Error('Internal Server Error'));
    }
  }

  static async createGlobalDeploy(
    projectId,
    fileIDS,
    releaseID,
    type,
    value,
    comments,
    target,
    targetMarket,
    exclude,
    excludeMarket,
  ) {
    try {
      const userAuth = await Auth.currentAuthenticatedUser();

      const postData = await API.graphql({
        query: createGlobalDeploy,
        variables: {
          projectId,
          fileIDS: fileIDS.length > 0 ? fileIDS : undefined,
          releaseID,
          type,
          value,
          comments,
          deployRegEx: undefined,
          user: userAuth?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userAuth?.attributes?.email,
          target,
          targetMarket,
          exclude,
          excludeMarket,
        },
        authToken: userAuth.signInUserSession.accessToken.jwtToken,
      });

      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.createGlobalDeploy.__typename === 'DeploymentOutputSuccess') {
        return postData.data.createGlobalDeploy.deployResult;
      }
      return Promise.reject(new Error(postData.data.createGlobalDeploy.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async deleteDeploy(projectId, deployId) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: deleteDeploy,
        variables: {
          projectId,
          deployId,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.deleteDeploy.__typename === 'DeleteDeploySuccess') {
        return postData.data.deleteDeploy.response;
      }
      return Promise.reject(new Error(postData.data.deleteDeploy.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async deleteBundle(projectId, releaseID) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: deleteBundle,
        variables: {
          projectId,
          releaseID,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      console.log('postData deleteBundle', postData);
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.deleteBundle.__typename === 'DeleteBundleSuccess') {
        return postData.data.deleteBundle.response;
      }
      return Promise.reject(new Error(postData.data.deleteBundle.message));
    } catch (data) {
      console.log('catch error deleteBundle', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async deleteAsset(projectId, deleteAssetInput) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: deleteAsset,
        variables: {
          projectId,
          deleteAssetInput,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.deleteAsset.__typename === 'DeleteAssetSuccess') {
        return postData.data.deleteAsset.response;
      }
      return Promise.reject(new Error(postData.data.deleteAsset.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async updateAssetDescription(projectId, fileId, assetDescription) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await API.graphql({
        query: updateAssetDescription,
        variables: {
          projectId,
          fileId,
          assetDescription,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });
      if (result.errors || !result.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (result.data.updateAssetDescription.__typename === 'UpdateAssetDescriptionSuccess') {
        return result.data.updateAssetDescription.response;
      }
      return Promise.reject(new Error(result.data.updateAssetDescription.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getNotifications({
    user,
  }) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getNotifications,
        variables: {
          user,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });

      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getNotifications.__typename === 'NotificationsSuccess') {
        return postData.data.getNotifications.notifications;
      }
      return Promise.reject(new Error(postData.data.getNotifications.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getConfig() {
    try {
      const postData = await API.graphql({
        query: getConfig,
        authToken: 'auth',
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getConfig.__typename === 'GetConfigSuccess') {
        return postData.data.getConfig.data;
      }
      return Promise.reject(new Error(postData.data.getConfig.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async getUnreadNotificationsCount({
    user,
  }) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getUnreadNotificationsCount,
        variables: {
          user,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getUnreadNotificationsCount.__typename === 'UnreadNotificationsCountSuccess') {
        return postData.data.getUnreadNotificationsCount.count;
      }
      return Promise.reject(new Error(postData.data.getUnreadNotificationsCount.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async getUnreadNotifications({
    user,
  }) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getUnreadNotifications,
        variables: {
          user,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getNotifications.__typename === 'NotificationsSuccess') {
        return postData.data.getNotifications.notifications;
      }
      return Promise.reject(new Error(postData.data.getNotifications.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async createNotification(email, notificationTitle, notificationDescription, notificationType = 'info') {
    try {
      const userToken = await Auth.currentAuthenticatedUser();

      const variables = {
        title: notificationTitle,
        description: notificationDescription,
        user: email,
        type: notificationType,
        userEmail: email,
        jwtToken: userToken.signInUserSession.accessToken.jwtToken,
      };

      const postData = await API.graphql({
        query: createNotification,
        variables,
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.createNotification.__typename === 'NotificationSuccess') {
        return postData.data.createNotification.notification;
      }
      return Promise.reject(new Error(postData.data.createNotification.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async deleteNotificationsByTitle(title) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: deleteNotificationsByTitle,
        variables: {
          title,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.deleteNotificationsByTitle.__typename === 'DeleteNotificationSuccess') {
        return postData.data.deleteNotificationsByTitle.response;
      }
      return Promise.reject(new Error(postData.data.deleteNotificationsByTitle.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async markAsReadMutation(id) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: markAsReadMutation,
        variables: {
          id,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.markAsRead.__typename === 'MarkNotificationSuccess') {
        return postData.data.markAsRead.response;
      }
      return Promise.reject(new Error(postData.data.markAsRead.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async updateConfig(config) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: updateConfig,
        variables: {
          config,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.updateConfig.__typename === 'UpdateConfigSuccess') {
        return postData.data.updateConfig.response;
      }
      return Promise.reject(new Error(postData.data.updateConfig.message));
    } catch (err) {
      return Promise.reject(new Error(`Internal Server Error: ${err.errors[0].message}`));
    }
  }

  static async deleteNotification(id) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: deleteNotification,
        variables: {
          id,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.deleteNotification.__typename === 'DeleteNotificationSuccess') {
        return postData.data.deleteNotification.response;
      }
      return Promise.reject(new Error(postData.data.deleteNotification.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data.errors[0].message}`));
    }
  }

  static async getSRFToken({
    privateKey,
    payload,
    algorithm,
  }) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getSRFToken,
        variables: {
          privateKey,
          payload,
          algorithm,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getSRFToken.__typename === 'GetSRFTokenSuccess') {
        return postData.data.getSRFToken.result;
      }
      return Promise.reject(new Error(postData.data.getSRFToken.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async getSRFRequests({
    projectId, machineId,
  }) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getSRFRequests,
        variables: {
          projectId,
          machineId,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });

      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getSRFRequests.__typename === 'GetSRFRequestsSuccess') {
        return postData.data.getSRFRequests.result;
      }
      return Promise.reject(new Error(postData.data.getSRFRequests.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async getSRFRequest({
    projectId, reqId,
  }) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getSRFRequest,
        variables: {
          projectId,
          reqId,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getSRFRequest.__typename === 'GetSRFRequestSuccess') {
        return postData.data.getSRFRequest.result;
      }
      return Promise.reject(new Error(postData.data.getSRFRequest.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async getCertificateQuota(
    awsUserId,
  ) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getCertificateQuota,
        variables: {
          awsUserId,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data) {
        console.error('Error getCertificateQuota: ', postData.errors);
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getCertificateQuota.__typename === 'GetCertificateQuotaSuccess') {
        return postData.data.getCertificateQuota.result;
      }
      return Promise.reject(new Error(postData.data.getCertificateQuota.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async getCertificateStatus(certificateId) {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getCertificateStatus,
        variables: {
          certificateId,
        },
        authToken: userToken.signInUserSession.accessToken.jwtToken,
      });
      if (postData.errors || !postData.data || postData.data?.getCertificateStatus?.statusCode === 500) {
        console.error('Error getCertificateStatus: ', postData.errors);
        return Promise.reject(new Error('Internal Server Error'));
      }
      if (postData.data.getCertificateStatus.__typename === 'GetCertificateStatusSuccess') {
        return postData.data.getCertificateStatus.result;
      }
      return Promise.reject(new Error(postData.data.getCertificateStatus.message));
    } catch (data) {
      return Promise.reject(new Error(`Internal Server Error: ${data}`));
    }
  }

  static async deleteWave(projectId, waveID) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: deleteWave,
        variables: {
          projectId,
          waveID,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.deleteWave.__typename === 'DeleteWaveSuccess') {
        return postData.data.deleteWave.result;
      }

      return Promise.reject(new Error(postData.data.deleteWave.message));
    } catch (data) {
      console.error('Error in deleteWave:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async updateWave(projectId, waveID, waveDescription) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: updateWave,
        variables: {
          projectId,
          waveID,
          waveDescription,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.updateWave.__typename === 'UpdateWaveSuccess') {
        return postData.data.updateWave.result;
      }

      return Promise.reject(new Error(postData.data.updateWave.message));
    } catch (data) {
      console.error('Error in updateWave:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getWaveById(projectId, waveID) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getWaveById,
        variables: {
          projectId,
          waveID,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (postData.errors || !postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getWaveById.__typename === 'GetWaveByIdSuccess') {
        return postData.data.getWaveById.result;
      }

      return Promise.reject(new Error(postData.data.getWaveById.message));
    } catch (data) {
      console.error('Error in getWaveById:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async listPaginatedWaves({
    projectId,
    page,
    pageSize,
    sortDirection,
    datetimeFrom,
    datetimeTo,
    waveDescriptionKey,
    waveDescriptionValue,
    fileType,
    fileID,
    releaseID,
    deployID,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: listPaginatedWaves,
        variables: {
          projectId,
          page,
          pageSize,
          sortDirection,
          datetimeFrom,
          datetimeTo,
          waveDescriptionKey,
          waveDescriptionValue,
          fileType,
          fileID,
          releaseID,
          deployID,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.listPaginatedWaves.__typename === 'ListPaginatedWavesSuccess') {
        return postData.data.listPaginatedWaves;
      }

      return Promise.reject(new Error(postData.data.listPaginatedWaves.message));
    } catch (data) {
      console.error('Error in listPaginatedWaves:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async onboardingDevice({
    projectId,
    serialNumber,
    topicNumber,
    payload,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: onboardingDevice,
        variables: {
          projectId,
          serialNumber,
          topicNumber,
          payload,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.onboardingDevice.__typename === 'OnboardingDeviceSuccess') {
        return postData.data.onboardingDevice.result;
      }

      return Promise.reject(new Error(postData.data.onboardingDevice.message));
    } catch (data) {
      console.error('Error in onboardingDevice:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async subscribeToTopic({
    projectId,
    serialNumber,
    topicNumber,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: subscribeToTopic,
        variables: {
          projectId,
          serialNumber,
          topicNumber,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.subscribeToTopic.__typename === 'SubscribeToTopicSuccess') {
        return postData.data.subscribeToTopic.result;
      }

      return Promise.reject(new Error(postData.data.subscribeToTopic.message));
    } catch (data) {
      console.error('Error in subscribeToTopic:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getIotMessages({
    projectId,
    serialNumber,
    topicNumber,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getIotMessages,
        variables: {
          projectId,
          serialNumber,
          topicNumber,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getIotMessages.__typename === 'GetIotMessagesSuccess') {
        return postData.data.getIotMessages.result;
      }

      return Promise.reject(new Error(postData.data.getIotMessages.message));
    } catch (data) {
      console.error('Error in getIotMessages:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  // getPlatformHealth
  static async getPlatformHealth({ brands, services, date }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getPlatformHealth,
        variables: {
          brands,
          services,
          date,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getPlatformHealth.__typename === 'GetPlatformHealthSuccess') {
        return postData.data.getPlatformHealth.result;
      }

      return Promise.reject(new Error(postData.data.getPlatformHealth.message));
    } catch (data) {
      console.error('Error in getPlatformHealth:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getServicesHealthHistory({ services, from, to }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getServicesHealthHistory,
        variables: {
          services,
          from,
          to,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getServicesHealthHistory.__typename === 'GetServicesHealthHistorySuccess') {
        return postData.data.getServicesHealthHistory.result;
      }

      return Promise.reject(new Error(postData.data.getServicesHealthHistory.message));
    } catch (data) {
      console.error('Error in getServicesHealthHistory:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getServicesHealthHistoryByBrands({ brands, from, to }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getServicesHealthHistoryByBrands,
        variables: {
          brands,
          from,
          to,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getServicesHealthHistoryByBrands.__typename === 'GetServicesHealthHistoryByBrandsSuccess') {
        return postData.data.getServicesHealthHistoryByBrands.result;
      }

      return Promise.reject(new Error(postData.data.getServicesHealthHistoryByBrands.message));
    } catch (data) {
      console.error('Error in getServicesHealthHistoryByBrands:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getTestsExecutions({
    service, date,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getTestsExecutions,
        variables: {
          service,
          date,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getTestsExecutions.__typename === 'GetTestsExecutionsSuccess') {
        return postData.data.getTestsExecutions.result;
      }

      return Promise.reject(new Error(postData.data.getTestsExecutions.message));
    } catch (data) {
      console.error('Error in getTestsExecutions:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async triggerTestsExecutions({ brands, tests }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: triggerTestsExecutions,
        variables: {
          brands,
          tests,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.triggerTestsExecutions.__typename === 'TriggerTestsExecutionsSuccess') {
        return postData.data.triggerTestsExecutions.result;
      }

      return Promise.reject(new Error(postData.data.triggerTestsExecutions.message));
    } catch (data) {
      console.error('Error in triggerTestsExecutions:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async triggerHealthCalculation() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: triggerHealthCalculation,
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.triggerHealthCalculation.__typename === 'TriggerHealthCalculationSuccess') {
        return postData.data.triggerHealthCalculation.result;
      }

      return Promise.reject(new Error(postData.data.triggerHealthCalculation.message));
    } catch (data) {
      // Handle the error appropriately
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getDevicesPermissionsByProject(projectId) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getDevicesPermissionsByProject,
        variables: {
          projectId,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getDevicesPermissionsByProject.__typename === 'GetDevicesPermissionsByProjectSuccess') {
        return postData.data.getDevicesPermissionsByProject.result;
      }

      return Promise.reject(new Error(postData.data.getDevicesPermissionsByProject.message));
    } catch (data) {
      console.error('Error in getDevicesPermissionsByProject:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async createDevicePermission(projectId, device) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: createDevicePermission,
        variables: {
          projectId,
          device,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.createDevicePermission.__typename === 'CreateDevicePermissionSuccess') {
        return postData.data.createDevicePermission.result;
      }

      return Promise.reject(new Error(postData.data.createDevicePermission.message));
    } catch (data) {
      console.error('Error in createDevicePermission:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async deleteDevicePermission(projectId, device) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: deleteDevicePermission,
        variables: {
          projectId,
          device,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.deleteDevicePermission.__typename === 'DeleteDevicePermissionSuccess') {
        return postData.data.deleteDevicePermission.result;
      }

      return Promise.reject(new Error(postData.data.deleteDevicePermission.message));
    } catch (data) {
      console.error('Error in deleteDevicePermission:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async deleteAllDevicesPermissions(projectId) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const devicesPermissions = await this.getDevicesPermissionsByProject(projectId);

      const deletePromises = devicesPermissions.map((device) => this.deleteDevicePermission(projectId, device));

      await Promise.all(deletePromises);
      return { success: true };
    } catch (error) {
      console.error('Error in deleteAllDevicesPermissions:', error);
      return Promise.reject(new Error(`Internal Server Error: ${error.message}`));
    }
  }

  static async getDeviceConnectivityStatus({ projectId, serialNumber }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getDeviceConnectivityStatus,
        variables: {
          projectId,
          serialNumber,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getDeviceConnectivityStatus.__typename === 'GetDeviceConnectivityStatusSuccess') {
        return postData.data.getDeviceConnectivityStatus.result;
      }

      return Promise.reject(new Error(postData.data.getDeviceConnectivityStatus.message));
    } catch (data) {
      console.error('Error in getDeviceConnectivityStatus:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getDeviceConnectivityStatusHistory({
    projectId, serialNumber, from, to, paginationToken,
  }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getDeviceConnectivityStatusHistory,
        variables: {
          projectId,
          serialNumber,
          from,
          to,
          paginationToken,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getDeviceConnectivityStatusHistory.__typename === 'GetDeviceConnectivityStatusHistorySuccess') {
        return postData.data.getDeviceConnectivityStatusHistory.result;
      }

      return Promise.reject(new Error(postData.data.getDeviceConnectivityStatusHistory.message));
    } catch (data) {
      console.error('Error in getDeviceConnectivityStatusHistory:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getShadowState({ projectId, serialNumber }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getShadowState,
        variables: {
          projectId,
          serialNumber,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getShadowState.__typename === 'GetShadowStateSuccess') {
        return postData.data.getShadowState.result;
      }

      return Promise.reject(new Error(postData.data.getShadowState.message));
    } catch (data) {
      console.error('Error in getShadowState:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }

  static async getNewUsersBetweenDates(from, to) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const postData = await API.graphql({
        query: getNewUsersBetweenDates,
        variables: {
          from,
          to,
        },
        authToken: user.signInUserSession.accessToken.jwtToken,
      });

      if (!postData.data) {
        return Promise.reject(new Error('Internal Server Error'));
      }

      if (postData.data.getNewUsersBetweenDates.__typename === 'GetNewUsersBetweenDatesSuccess') {
        return postData.data.getNewUsersBetweenDates.result;
      }

      return Promise.reject(new Error(postData.data.getNewUsersBetweenDates.message));
    } catch (data) {
      console.error('Error in getNewUsersBetweenDates:', data);
      return Promise.reject(new Error(`Internal Server Error: ${data.errors ? data.errors[0].message : data.message}`));
    }
  }
}
