/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, FormControlLabel, Switch, Typography, Button,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import JSONBuilderFilters, { valueTypes } from '../../components/utils/JSONBuilderFilters';
import BrandObjectTable from './MachineTelemetry/BrandObjectTable';
import { validate, calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../utils/dateUtils';
import FineGrainedPermissionsInfo from '../Admin/PlatformHealth/FineGrainedPermissions/FineGrainedPermissionsInfo';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  contentInfo: {
    paddingBottom: 8,
    backgroundColor: theme.palette.background.default,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  timestampToggle: {
    marginLeft: 16,
    marginBottom: 16,
  },
  noResultsMessage: {
    padding: 24,
    textAlign: 'center',
  },
  retryButtons: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
  },
}));

const jsonColumns = [

  {
    id: 'id', label: 'ID', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'device_serial', label: 'Device Serial', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'module_serial', label: 'Module Serial', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'country_code', label: 'Country Code', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'event_code', label: 'Event Code', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'is_valid_time', label: 'Is Valid Time', valueType: valueTypes.BOOLEAN, values: ['true', 'false'],
  },
  {
    id: 'extra', label: 'Extra', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'created_on_local', label: 'Created On Local', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'created_on_utc', label: 'Created On UTC', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'received_on_utc', label: 'Received On UTC', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'processed_on_utc', label: 'Processed On UTC', valueType: valueTypes.DATE, values: [],
  },
];
export default function BrandEvents({ openSnackBar }) {
  const MAX_DAYS_BETWEEN_DATES = 30;
  const params = { add_dict: true };
  // STATE________________________________
  const { project } = useProject();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageConsumption] = useState(10);
  const [reducedResponse, setReducedResponse] = useState(null);

  // timestamp type toggle
  const [useProcessedTimestamp, setUseProcessedTimestamp] = useState(false);
  const [noResultsDialogOpen, setNoResultsDialogOpen] = useState(false);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-29, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-29, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));

  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(true);

  const [filterError, setFilterError] = useState('');

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  // functions to obtain the data to show
  async function searchObject(newPage, useProcessedTime = useProcessedTimestamp) {
    const startDate = calculateStartDateTimeIso(selectedStartDate, selectedStartTime);
    const endDate = calculateEndDateTimeIso(selectedEndDate, selectedEndTime);
    setDateRangePickerVisibility(!dateRangePickerVisibility);

    // Pass timestamp type parameter to the API
    const searchParams = { ...params, use_processed_timestamp: useProcessedTime };

    const consumptionResponse = await GraphqlService.getTelemetryObject(
      project.code, 'events', JSON.stringify(searchParams), selectedFilters, startDate, endDate, rowsPerPageConsumption, (newPage || 0) * rowsPerPageConsumption, MAX_DAYS_BETWEEN_DATES, useProcessedTimestamp,
    );

    // eslint-disable-next-line no-plusplus
    if (consumptionResponse) {
      if (totalCount < 1 || !newPage) {
        setTotalCount(consumptionResponse.pagination.totalRows);
      }
      return JSON.parse(consumptionResponse.data);
    }
    return [];
  }

  async function search(newPage, useProcessedTime = useProcessedTimestamp) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchObject(newPage, useProcessedTime);
      setPaginationTokens(['']);
      setReducedResponse(creationResponse);

      // Check if no results found with machine's timestamp and show dialog
      if (creationResponse ? creationResponse.length === 0 && !useProcessedTime : false) {
        setNoResultsDialogOpen(true);
      }

      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSearch = (event) => {
    event.preventDefault();
    if (!validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) return;
    search();
  };

  useEffect(() => {
    // Reset no results message when filters change
    setNoResultsDialogOpen(false);
  }, [useProcessedTimestamp]);

  const handleRetryWithProcessedTimestamp = () => {
    // Automatically adjust date range to last 3 days
    const newEndDate = moment();
    const newStartDate = moment(newEndDate).subtract(3, 'days');

    // Update date and time state variables
    setSelectedEndDate(newEndDate);
    setSelectedEndTime(newEndDate.format('23:59'));
    setSelectedStartDate(newStartDate);
    setSelectedStartTime(newStartDate.format('00:00'));

    // Enable processed timestamp toggle
    setUseProcessedTimestamp(true);

    // Close dialog
    setNoResultsDialogOpen(false);

    // Perform search with processed timestamp
    setTimeout(() => {
      search(0, true);
    }, 100);
  };

  const handleKeepResults = () => {
    setNoResultsDialogOpen(false);
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedFilters, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    // Reset no results message when timestamp type changes
    setNoResultsDialogOpen(false);
  }, [useProcessedTimestamp]);

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEVICE EVENTS" />

      <FineGrainedPermissionsInfo
        open={false}
        onClose={() => { }}
        style={{ margin: 24 }}
      />

      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          xs={12}
          className={classes.contentInfo}
        >
          <JSONBuilderFilters
            id="DeviceEvents"
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            submitting={submitting}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedStartTime={selectedStartTime}
            setSelectedStartTime={setSelectedStartTime}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            selectedEndTime={selectedEndTime}
            setSelectedEndTime={setSelectedEndTime}
            filtersVisible
            deviceSerialVisible={false}
            jsonColumns={jsonColumns}
            setDateRangePickerVisibility={dateRangePickerVisibility}
            onSearch={onSearch}
            setFilterError={setFilterError}
            filterError={filterError}
            useProcessedTimestamp={useProcessedTimestamp}
            setUseProcessedTimestamp={setUseProcessedTimestamp}
            showTimestampToggle
          />

        </Grid>

        {/* Replace the conditional message with Dialog */}
        <Dialog
          open={noResultsDialogOpen}
          onClose={handleKeepResults}
          aria-labelledby="no-results-dialog-title"
          aria-describedby="no-results-dialog-description"
        >
          <DialogTitle id="no-results-dialog-title">No Results Found</DialogTitle>
          <DialogContent>
            <DialogContentText id="no-results-dialog-description">
              Your search was conducted using the machine&apos;s timestamp (created_on).
              Please note that there may be instances where this timestamp is incorrectly set,
              which could affect the search results.
            </DialogContentText>
            <DialogContentText style={{ marginTop: 8 }}>
              Would you like to perform the search using the processed timestamp (processed_on) instead?
              This will automatically adjust your search to the last 3 days.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRetryWithProcessedTimestamp}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={handleKeepResults}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Grid item xs={12}>
          <BrandObjectTable
            search={(newPage) => search(newPage)}
            dataList={reducedResponse}
            totalCount={totalCount}
            filters={selectedFilters}
            loading={loading}
            dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
            dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
            rangeDates={MAX_DAYS_BETWEEN_DATES}
            page={reducedPage}
            setPage={setReducedPage}
            rowsPerPage={rowsPerPageConsumption}
            object="events"
            params={JSON.stringify(params)}
            id="DeviceEvents"
            setFilterError={setFilterError}
            filterError={filterError}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
BrandEvents.propTypes = {
  openSnackBar: PropTypes.func.isRequired,
};
