import React from 'react';
import {
  Grid, Box, makeStyles,
  Typography, LinearProgress,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  machineInfoSubSection: {

  },
  machineInfoSubSection12: {

  },
  machineInfoCardTitle: {

  },
  machineInfoCardFontSize16: {
    fontSize: '16px',
  },
  machineInfoCardSectionContent: {

  },
  machineInfoCardPaddingLeft16: {
    paddingLeft: '16px',
  },
  machineInfoCardExtraField: {

  },
  machineInfoCardFontSize14: {
    fontSize: '14px',
  },
  machineInfoCardSectionContentModules: {

  },
  machineInfoCardPaddingLeft16NoTop: {
    paddingLeft: '16px',
    paddingTop: '0px',
  },
  detailsTitle: {

  },
}));

const FirmwaresNamesStartDot = () => (
  <Box style={{
    backgroundColor: '#7986cb', // Assuming this is the primary.subMain color from your theme
    height: 8,
    width: 8,
    borderRadius: 90,
    marginRight: 8,
    marginBottom: 3,
    marginTop: 4,
    verticalAlign: 'middle',
    display: 'inline-flex',
  }}
  />
);

function DeviceInfoTab({ machineInfo, isLoading }) {
  const classes = useStyles();

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!machineInfo) {
    return <Typography>No Device Info available.</Typography>;
  }

  return (
    <Grid container>
      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Grid item xs={12} className={classes.machineInfoSubSection}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="stretch"
          >
            <Grid item xs={12} className={classes.machineInfoCardTitle}>
              <Typography variant="h4" display="inline" className={classes.machineInfoCardFontSize16}>Device </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className={classes.machineInfoCardSectionContent}
            >

              <Grid item xs={2} className={classes.machineInfoCardPaddingLeft16}>
                <FirmwaresNamesStartDot />
                <Typography className={classes.detailsTitle} variant="title" noWrap>File name </Typography>
                <Grid item xs={12} className={classes.machineInfoCardExtraField}>
                  <Typography id="txtFileNameDevice" display="inline" className={classes.machineInfoCardFontSize14}>
                    v
                    {' '}
                    {machineInfo.deviceFw}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={10} className={classes.machineInfoCardPaddingLeft16}>
                <FirmwaresNamesStartDot />
                <Typography className={classes.detailsTitle} variant="title" noWrap>Extra fields </Typography>
                {machineInfo.extraDeviceFw.map((resp) => (
                  <Grid item xs={12} className={classes.machineInfoCardExtraField} key={`extra-device-${resp.field}`}>
                    <Typography id={`txtExtraFieldDevice_${resp.field}`} className={classes.machineInfoCardFontSize14}>
                      {resp.field}
                    </Typography>
                    <Typography id={`txtExtraValueDevice_${resp.field}`} className={classes.machineInfoCardFontSize14} variant="subheading">
                      {':  '}
                      v
                      {' '}
                      {resp.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        {(machineInfo.gatewayApp !== null) && (
          <Grid item xs={12} className={classes.machineInfoSubSection}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="stretch"
            >
              <Grid item xs={12} className={classes.machineInfoCardTitle}>
                <Typography variant="h4" display="inline" className={classes.machineInfoCardFontSize16}>Gateway </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className={classes.machineInfoCardSectionContent}
              >
                <Grid item xs={12} className={classes.machineInfoCardPaddingLeft16}>
                  <FirmwaresNamesStartDot />
                  <Typography className={classes.detailsTitle} variant="title" noWrap>Asset </Typography>
                  <Grid item xs={12} className={classes.machineInfoCardExtraField}>
                    <Typography id="txtFirmwareGateway" className={classes.machineInfoCardFontSize14} variant="subheading" noWrap>
                      v
                      {' '}
                      {machineInfo.gatewayApp}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {(machineInfo.modules.length > 0) && (
          <Grid item xs={12} className={classes.machineInfoSubSection12}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="stretch"
            >
              <Grid item xs={12} className={classes.machineInfoCardTitle}>
                <Typography variant="h4" display="inline" className={classes.machineInfoCardFontSize16}>Modules </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {machineInfo.modules.map((resp) => (
                  <Grid item xs={4} className={classes.machineInfoCardSectionContentModules} key={`module-${resp.serial}`}>
                    <Grid item xs={12} className={classes.machineInfoCardPaddingLeft16}>
                      <FirmwaresNamesStartDot />
                      <Typography className={classes.detailsTitle} variant="title" noWrap>Module </Typography>
                      <Grid item xs={12} className={classes.machineInfoCardExtraField}>
                        <Typography id={`txtSerialModules_${resp.serial}`} className={classes.machineInfoCardFontSize14} variant="subheading">{resp.serial}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.machineInfoCardPaddingLeft16NoTop}>
                      <FirmwaresNamesStartDot />
                      <Typography className={classes.detailsTitle} variant="title" noWrap>Asset </Typography>
                      <Grid item xs={12} className={classes.machineInfoCardExtraField}>

                        <Typography id={`txtFirmwareModules_${resp.serial}`} className={classes.machineInfoCardFontSize14} variant="subheading">
                          v
                          {' '}
                          {resp.moduleFw}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FirmwaresNamesStartDot />
                        <Typography className={classes.detailsTitle} variant="title" noWrap>Extra fields </Typography>
                        {resp.extraDeviceFw.map((respExtra) => (
                          <Grid item xs={12} className={classes.machineInfoCardExtraField} key={`module-${resp.serial}-${respExtra.field}`}>
                            <Typography id={`txtExtraFieldModule_${resp.serial}_${respExtra.field}`} className={classes.machineInfoCardFontSize14}>
                              {respExtra.field}
                            </Typography>
                            <Typography id={`txtExtraValueModule_${resp.serial}_${respExtra.field}`} className={classes.machineInfoCardFontSize14} variant="subheading">
                              {':  '}
                              v
                              {' '}
                              {respExtra.value}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

DeviceInfoTab.propTypes = {
  machineInfo: PropTypes.shape({
    deviceFw: PropTypes.string,
    extraDeviceFw: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.string,
    })),
    gatewayApp: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.shape({
      serial: PropTypes.string,
      moduleFw: PropTypes.string,
      extraDeviceFw: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        value: PropTypes.string,
      })),
    })),
  }),
  isLoading: PropTypes.bool,
};

DeviceInfoTab.defaultProps = {
  machineInfo: {
    deviceFw: '',
    extraDeviceFw: [],
    gatewayApp: '',
    modules: [],
  },
  isLoading: false,
};

export default DeviceInfoTab;
