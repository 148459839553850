const changelog = `# Updates
All notable changes to this project will be documented in this page.

Stay updated with every version's enhancements and optimizations to ensure you're making the most out of our application.

The format is based on [Keep a Changelog](https://keepachangelog.com/en/1.0.0/),
and this project adheres to [Semantic Versioning](https://semver.org/spec/v2.0.0.html).

## v3.15.0 (18.03.2025)

**New**

- **Device Overview:** Users can now view all relevant information about a specific device in one consolidated page. The new feature eliminates the need for users to navigate through multiple pages and repeatedly enter the device serial number. This streamlines the process and saves time, enabling users to quickly access the information they need. This release includes new device information: connectivity status, connectivity history and shadow wrapper.

- **Enhanced Device Consumptions and Events search:** A new toggle feature has been implemented for those searches, allowing users to select between machine's timestamp and processed timestamp. This improves search accuracy and provides guidance when no results are found due to incorrect machine timestamps.

**Updates**

- **New filters in Deployments list:** Users can now filter de deployment list also by deploy ID and/or comments/description.

- **Bug fixes**

## v3.14.0 (11.02.2025)

**New**

- **Fine-grained permissions:** The implementation of this feature enhances security by restricting developers from deploying firmware to specific devices. This ensures new firmware is validated on a limited scope, minimizing the risk of widespread device malfunctions. Additionally, telemetry access is restricted to maintain consistency and protect sensitive data.

## v3.13.0 (20.01.2025)

**New**

- **IoT Platform Health:** Admin users now have the capability to monitor the Platform Health directly within the Portal.

**Updated**

- The "New IoT Asset Version Analysis and Asset Deployment" ServiceNow form has been enhanced and is now available in the Service Request menu.

- A "Raise an Incident" link has been added to the Service Request menu, allowing users to easily redirect to ServiceNow to raise an incident directly from the Portal.

## v3.12.0 (17.12.2024)

**New**

- **Simulated Client as a Service:** users can now simulate MQTT messages from device to cloud. This new feature offers a great support to the development integration activities.

**Updated**

- Bug Fixes

## v3.11.0 (27.11.2024)

**Updated**

- **Error messages:** conveying of error messages from the platform APIs is update all across the Portal.
- Bug Fixes in Bundles
- Bug Fixes in Waves

## v3.10.0 (30.10.2024)
 
**New**
 
- **Waves:** a wave entity is created for deployment of assets or asset bundles which increases traceability. User is able to delete all deployments associated with the wave in one transaction.

`;

export default changelog;
