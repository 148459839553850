/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Chip,
  Typography,
  IconButton,
  Button,
  Tooltip,
  Select,
} from '@material-ui/core';

import { useQuery, useMutation } from '@tanstack/react-query';
import moment from 'moment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import DescriptionIcon from '@material-ui/icons/Description';
import { useSnackbar } from '../../../providers/SnackbarContext';
import PageBanner from '../../../components/utils/PageBanner';
import GraphqlService from '../../../service/graphqlService';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { target } from '../../../config/awsExports';
import TestDetailPopup from './TestDetailPopup';

const HEALTH_URL = 'https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#alarmsV2:alarm';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
    padding: 16,
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  separate: {
    marginBottom: theme.spacing(2),
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  selectProject: {
    width: '100%',
    maxWidth: 450,
  },
  chartContainer: {
    width: '100%',
    height: '200px',
    position: 'relative',
  },
}));

export default function PlatformHealth() {
  const classes = useStyles();
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [servicesColumns, setServicesColumns] = useState([]);
  const [servicesInBrands, setServicesInBrands] = useState([]);
  const [labels, setLabels] = useState([]);
  const [healthStatus, setHealthStatus] = useState([0, 1, 2]);
  const [pointColors, setPointColors] = useState([]);
  const [selectorDates, setSelectorDates] = useState([]);
  const openSnackBar = useSnackbar();
  // Get the current date and time
  const now = moment();

  const healthColors = {
    GOOD: 'green',
    WARNING: 'orange',
    IMPROVABLE: 'orange',
    BAD: 'red',
    POOR: 'red',
  };

  const healthLevels = {
    GOOD: 0,
    WARNING: 1,
    IMPROVABLE: 1,
    BAD: 2,
    POOR: 2,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            const index = tooltipItem.dataIndex;
            const health = Object.keys(healthLevels).find(
              (key) => healthLevels[key] === healthStatus[index],
            );
            let emoji;
            if (health === 'GOOD') {
              emoji = '✅';
            } else if (health === 'WARNING' || health === 'IMPROVABLE') {
              emoji = '⚠️';
            } else {
              emoji = '❌';
            }
            return `${emoji} ${health}`;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 30, // Extra space for the x-axis labels
      },
    },
    scales: {
      x: {
        ticks: {
          callback: (value, index) => labels[index], // Use dates from `labels` array for x-axis
          font: {
            size: 12, // Adjust font size for better readability
          },
        },
      },
      y: {
        reverse: true, // Reverse the y-axis
        ticks: {
          callback(value) {
            if (value === 2) return '❌'; // BAD
            if (value === 1) return '⚠️'; // WARNING
            if (value === 0) return '✅'; // GOOD
            return '';
          },
          stepSize: 1,
          min: 0,
          max: 2,
          font: {
            size: 16, // Adjust font size for emojis
          },
          autoSkip: false, // Ensure all values are shown
          maxTicksLimit: 3, // Ensure all three values are shown
          values: [0, 1, 2],
        },
      },
    },
  };

  const servicesPerColumn = 10;

  const getPlatformHealth = async ({ queryKey }) => {
    const [, variables] = queryKey;
    const { brands } = variables;

    return GraphqlService.getPlatformHealth({
      brands: brands?.length > 0 ? brands : undefined,
      services: undefined,
      date: undefined,
    });
  };

  const getServicesHealthHistory = async ({ queryKey }) => {
    const [, variables] = queryKey;
    const { services } = variables;

    const data = await GraphqlService.getServicesHealthHistory({
      services,
      from: moment().subtract(15, 'days').toISOString(),
      to: moment().toISOString(),
    });

    // Transform data immutably
    const transformedData = {
      ...data,
      ServiceHealthHistory: data.ServiceHealthHistory?.map((history) => ({
        ...history,
        History: history.History.map((item) => ({
          ...item,
          healthPercentage: Math.round(item.healthPercentage || 0), // Ensure healthPercentage is an integer
        })),
      })),
    };

    return transformedData;
  };

  const getServicesHealthHistoryByBrands = async ({ queryKey }) => {
    const [, variables] = queryKey;
    const { brands, services } = variables;

    const data = await GraphqlService.getServicesHealthHistoryByBrands({
      brands,
      services,
      from: moment().subtract(15, 'days').toISOString(),
      to: moment().toISOString(),
    });

    // Transform data immutably
    const transformedData = {
      ...data,
      ServiceHealthHistory: data?.BrandsHealthHistory[0]?.ServiceHealthHistory?.map((history) => ({
        ...history,
        History: history.History?.map((item) => ({
          ...item,
          healthPercentage: Math.round(item.healthPercentage || 0), // Ensure healthPercentage is an integer
        })),
      })),
    };

    return transformedData;
  };

  const getTestsExecutions = async ({ queryKey }) => {
    const [, variables] = queryKey;
    const { service, date } = variables;

    return GraphqlService.getTestsExecutions({
      service: service || undefined,
      date: date || undefined,
    });
  };

  const getPlatformHealthServiceDetail = async ({ queryKey }) => {
    const [, variables] = queryKey; // Extract variables from queryKey
    const { brands, services, date } = variables;

    return GraphqlService.getPlatformHealth({
      brands: brands?.length > 0 ? brands : undefined,
      services: services || undefined,
      // format the date to 2024-12-18 00:06:28
      date: date || undefined,
    });
  };

  const {
    data: generalHealthData,
    isLoading: isLoadingGeneralHealthData,
  } = useQuery(
    ['getPlatformHealth', {
      brands: undefined,
      services: undefined,
      date: undefined,
    }],
    getPlatformHealth,
    {
      cacheTime: 100,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );

  const {
    data: brandsHealthData,
    isLoading: isLoadingBrandsHealth,
  } = useQuery(
    ['getPlatformHealthServiceDetail', {
      brands: selectedBrands?.map((brand) => brand.code) || undefined,
      services: selectedServices.length > 0 ? selectedServices : undefined,
      date: selectedDate,
    }],
    getPlatformHealthServiceDetail,
    {
      cacheTime: 100,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      enabled: selectedServices?.length > 0 || selectedBrands?.length > 0,
    },
  );

  const {
    data: serviceHealthHistory,
  } = useQuery(
    ['getServiceHealthHistory', {
      services: selectedServices.length > 0 ? selectedServices : undefined,
    }],
    getServicesHealthHistory,
    {
      cacheTime: 100,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      enabled: selectedServices?.length > 0 && selectedBrands?.length === 0,
    },
  );

  const {
    data: serviceHealthHistoryByBrand,
  } = useQuery(
    ['getServiceHealthHistoryByBrand', {
      brands: selectedBrands?.map((brand) => brand.code) || undefined,
      services: selectedServices.length > 0 ? selectedServices : undefined,
    }],
    getServicesHealthHistoryByBrands,
    {
      cacheTime: 100,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      enabled: selectedServices?.length > 0 && selectedBrands?.length > 0,
    },
  );

  // react query to getTestsExecutions(service, date)
  const {
    data: testsExecutions,
  } = useQuery(
    ['getTestsExecutions', {
      service: selectedServices.length > 0 ? selectedServices[0] : undefined,
      date: selectedDate,
    }],
    getTestsExecutions,
    {
      cacheTime: 100,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      enabled: selectedServices?.length > 0,
    },
  );

  const triggerTestsExecutions = useMutation(() => GraphqlService.triggerTestsExecutions({
    brands: undefined,
    tests: undefined,
  }));

  const triggerHealthCalculation = useMutation(() => GraphqlService.triggerHealthCalculation());

  const handleTriggerTestsExecutions = async () => {
    triggerTestsExecutions.mutate();
    openSnackBar('Tests executions triggered successfully');
    localStorage.setItem('lastTestExecution', new Date().toISOString());
  };

  const handleTriggerHealthCalculation = async () => {
    triggerHealthCalculation.mutate();
    openSnackBar('Health calculation triggered successfully');
    localStorage.setItem('lastHealthExecution', new Date().toISOString());
  };

  const getProjects = async () => GraphqlService.getProjects();

  const {
    data: projectsData,
  } = useQuery(['getProjects'], () => getProjects(), {
    cacheTime: 10000,
  });

  useEffect(() => {
    // Compute services
    // InBrands whenever brandsHealthData or selectedBrands changes
    if (selectedBrands?.length > 0 && brandsHealthData?.BrandHealth) {
      const computedServicesInBrands = brandsHealthData.BrandHealth.flatMap((brand) => brand.Service?.map((service) => service?.service));
      setServicesInBrands(computedServicesInBrands);
    } else {
      setServicesInBrands([]); // Reset if no brands are selected
    }
  }, [brandsHealthData, selectedBrands]);

  useEffect(() => {
    // Compute servicesToList based on servicesInBrands and generalHealthData
    const servicesToList = selectedBrands?.length > 0
      ? generalHealthData?.PlatformHealth?.Service?.filter((service) => servicesInBrands.includes(service.service))
      : generalHealthData?.PlatformHealth?.Service;

    const tempColumns = [];
    for (let i = 0; i < servicesToList?.length; i += servicesPerColumn) {
      tempColumns.push(servicesToList.slice(i, i + servicesPerColumn));
    }

    setServicesColumns(tempColumns);
  }, [generalHealthData, servicesInBrands, selectedBrands]);

  const handleAddProject = (selectedProject) => {
    if (selectedProject && !selectedBrands.some((brand) => brand.code === selectedProject.code)) {
      if (selectedServices.length > 0) {
        setSelectedBrands([]);
      }
      setSelectedBrands((prev) => [...prev, selectedProject]);
      setSelectedBrand(selectedProject.code);
    }
  };

  const handleDeleteProject = (projectCode) => {
    setSelectedBrands((prev) => prev.filter((brand) => brand.code !== projectCode));
    if (selectedBrand === projectCode) {
      setSelectedBrand('');
    }
  };

  const getIcon = (health, iconStyle = { fontSize: '2rem' }) => {
    if (!health) return null;
    switch (health) {
      case 'GOOD':
        return <CheckCircleIcon style={{ ...iconStyle, color: 'green' }} />;
      case 'WARNING':
        return <WarningIcon style={{ ...iconStyle, color: 'orange' }} />;
      case 'IMPROVABLE':
        return <WarningIcon style={{ ...iconStyle, color: 'orange' }} />;
      case 'POOR':
        return <CancelIcon style={{ ...iconStyle, color: 'red' }} />;
      default:
        return null;
    }
  };

  const handleOpenServiceDetail = (ServiceName) => {
    setSelectedServices([ServiceName]);
  };

  const handleAlarmExternalLink = (name) => {
    if (target === 'PRO') {
      const url = `${HEALTH_URL}/${name}`;
      window.open(url, '_blank');
    } else {
      const url = `${HEALTH_URL}/${name}`;
      window.open(url, '_blank');
    }
  };

  const handleBackToAllServices = () => {
    setSelectedServices([]);
  };

  const clearAndSetAllProjects = () => {
    setSelectedBrands([]);
    setSelectedBrand('');
  };

  useEffect(() => {
    if (!serviceHealthHistory?.ServiceHealthHistory?.[0]?.History && !serviceHealthHistoryByBrand?.ServiceHealthHistory?.[0]?.History) return;
    const serviceHealthHistoryData = selectedBrands?.length > 0 ? serviceHealthHistoryByBrand?.ServiceHealthHistory?.[0]?.History : serviceHealthHistory?.ServiceHealthHistory?.[0]?.History;
    const sortedHistory = serviceHealthHistoryData ? [...serviceHealthHistoryData]?.sort((a, b) => new Date(a.date) - new Date(b.date)) : [];
    setLabels(
      sortedHistory.map((item) => item.date.split('-').reverse().join('-')) || [],
    );
    setHealthStatus(
      sortedHistory.map((item) => healthLevels[item.health]) || [],
    );
    setPointColors(
      sortedHistory.map((item) => healthColors[item.health] || 'gray') || [],
    );
    const datesArray = serviceHealthHistory?.ServiceHealthHistory[0]?.History?.map((history) => moment(history.healthUpdatedAt, 'YYYY-MM-DD HH:mm'));
    setSelectorDates(datesArray);
    setSelectedDate(datesArray?.[0] || moment());
  }, [serviceHealthHistory, serviceHealthHistoryByBrand]);

  const chartData = {
    labels, // Your x-axis labels (dates)
    datasets: [
      {
        label: 'Health Evolution',
        data: healthStatus, // Actual health data
        backgroundColor: pointColors,
        borderColor: 'black',
        pointBackgroundColor: pointColors,
        pointBorderWidth: 2,
        pointHoverBackgroundColor: 'blue', // Add hover effects
        pointHoverBorderWidth: 32,
        pointRadius: labels.map((label, index) => (moment(label, 'DD-MM-YYYY').isSame(selectedDate, 'day') ? 10 : 6)),
        fill: false,
        tension: 0.3,
      },
      {
        label: 'Y-Axis Helper', // Hidden dataset for the y-axis range
        data: [0, 1, 2], // Ensure all y-axis values are present
        borderColor: 'transparent', // Invisible line
        backgroundColor: 'transparent',
        pointRadius: 0, // Hide points
        fill: false,
      },
    ],
  };

  if (!projectsData) {
    return (
      <Box>
        <PageBanner title="PLATFORM HEALTH" />
        <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">
          <Box>
            Loading projects...
          </Box>
        </Grid>
      </Box>
    );
  }

  // all alarms with "criticality": 0 should be ignored and not displayed in UI
  const renderAlarmFailureDetails = () => {
    const dataSource = selectedBrands?.length > 0 ? brandsHealthData?.BrandHealth : brandsHealthData?.PlatformHealth?.Service;
    return dataSource?.map((service) => service.failureDetails?.filter((detail) => detail.type === 'alarm' && detail.criticality !== 0)
      .map((detail) => (
        <Box
          key={detail.name}
          display="flex"
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
          alignContent={{ xs: 'center', sm: 'center' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          mb={1}
        >
          <Box
            key={detail.name}
            display="flex"
            alignItems={{ xs: 'flex-start' }}
          >
            <WarningIcon style={{ color: 'orange', alignSelf: 'flex-start', marginRight: '12' }} />
            <Typography variant="body1">{detail.name}</Typography>
          </Box>
          <IconButton
            onClick={(e) => handleAlarmExternalLink(detail.name)}
          >
            <ExitToAppIcon />
          </IconButton>
        </Box>
      )));
  };

  const renderAlarmFailureDetailsCount = () => {
    const dataSource = selectedBrands?.length > 0 ? brandsHealthData?.BrandHealth : brandsHealthData?.PlatformHealth?.Service;
    return dataSource?.map((service) => service.failureDetails?.filter((detail) => detail.type === 'alarm' && detail.criticality !== 0)
      .length);
  };

  const renderTestFailureDetails = () => {
    const filteredTestsExecutions = selectedBrands.length > 0
      ? testsExecutions?.filter((detail) => selectedBrands.some((brand) => detail.brandCode === brand.code))
      : testsExecutions;

    return filteredTestsExecutions?.map((detail) => (
      <Box key={detail.title} mb={2} display="flex" alignItems="flex-start">
        <Box>
          <CancelIcon style={{ color: 'red', alignSelf: 'flex-start', marginRight: '12' }} />
        </Box>

        <Typography variant="body1">{detail.title}</Typography>

        <TestDetailPopup
          testDetail={detail}
          renderOpener={({ onClick }) => (
            <IconButton
              onClick={onClick}
              color="primary"
              style={{ alignSelf: 'flex-start', padding: 0, marginLeft: 12 }}
            >
              <DescriptionIcon />
            </IconButton>
          )}
        />
      </Box>
    ));
  };

  const renderTestFailureDetailsCount = () => {
    const dataSource = selectedBrands?.length > 0 ? brandsHealthData?.BrandHealth : brandsHealthData?.PlatformHealth?.Service;
    return dataSource?.map((service) => service.failureDetails?.filter((detail) => detail.type === 'e2eTest').length);
  };

  return (
    <Box>
      <PageBanner title={selectedServices?.length > 0 ? `PLATFORM HEALTH/${selectedServices[0]}` : 'PLATFORM HEALTH'} />
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Box>
          <Box ml={2}>
            <Typography>
              Check the platform services status.
            </Typography>
          </Box>

          <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">

            <Box display={{ xs: 'block', sm: 'flex' }}>
              <TextField
                select
                variant="outlined"
                className={classes.selectProject}
                label={selectedBrands?.length > 0 ? 'Select project' : 'All projects'}
                value={selectedBrand || ''}
                onChange={(e) => {
                  const selectedCode = e.target.value;
                  const selectedProject = projectsData.find((p) => p.code === selectedCode);
                  handleAddProject(selectedProject);
                }}
                style={{ marginRight: '16px' }}
              >
                {projectsData
                  ?.sort((a, b) => a.code - b.code)
                  .map((project) => (
                    <MenuItem key={project.code} value={project.code}>
                      {`${project.code} - ${project.name}`}
                    </MenuItem>
                  ))}
              </TextField>
              <Button
                variant="contained"
                color="primary"
                className={classes.applyButton}
                onClick={clearAndSetAllProjects}
                id="btnClearProjects"
                disabled={selectedBrands?.length === 0}
                style={{ alignSelf: 'center' }}
              >
                Clear Projects
              </Button>
            </Box>

            <Box>
              <Box
                display="flex"
                alignItems="center"
                mt={4}
              >

                <Box>
                  <Typography>
                    {localStorage.getItem('lastTestExecution') && (
                      <Typography>
                        📌 e2e Tests calculated on
                        {' '}
                        {
                          generalHealthData?.PlatformHealth?.Overall?.e2eTestsUpdatedAt
                        }
                      </Typography>
                    )}
                  </Typography>
                </Box>

              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              mt={2}
            >

              <Box>
                <Typography>
                  {localStorage.getItem('lastHealthExecution') && (
                    <Typography>
                      📌 Health Calculation on
                      {' '}
                      {
                        generalHealthData?.PlatformHealth?.Overall?.healthUpdatedAt

                      }
                    </Typography>
                  )}
                </Typography>
              </Box>

            </Box>

            <Box className={classes.chipContainer}>
              {selectedBrands.map((project) => (
                <Chip
                  key={project?.code}
                  label={`${project?.code} - ${project?.name}`}
                  onDelete={() => handleDeleteProject(project.code)}
                />
              ))}
            </Box>
          </Grid>

        </Box>

        <Box mr={12} alignItems="center">
          <Typography variant="h4">
            Services Health Summary
          </Typography>
          <Box display="flex" flexDirection="row" alignContent="center" alignItems="center" justifyContent="center" mt={4}>

            <Box display="flex" flexDirection="column" alignItems="center" mr={6}>
              <Box
                width={60}
                height={60}
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="green"
                color="white"
                fontWeight="bold"
              >
                {generalHealthData?.PlatformHealth?.Service?.filter((service) => service.health === 'GOOD').length || 0}
              </Box>
              <Typography>GOOD</Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" mr={6}>
              <Box
                width={60}
                height={60}
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="orange"
                color="white"
                fontWeight="bold"
              >
                {generalHealthData?.PlatformHealth?.Service?.filter((service) => service.health === 'WARNING' || service.health === 'IMPROVABLE').length || 0}
              </Box>
              <Typography>WARNING</Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" mr={6}>
              <Box
                width={60}
                height={60}
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="red"
                color="white"
                fontWeight="bold"
              >
                {generalHealthData?.PlatformHealth?.Service?.filter((service) => service.health === 'BAD' || service.health === 'POOR').length || 0}
              </Box>
              <Typography>POOR</Typography>
            </Box>

          </Box>
        </Box>
      </Box>

      {
        (isLoadingGeneralHealthData || ((selectedBrands?.length > 0 || selectedServices.length > 0) && isLoadingBrandsHealth)) ? (
          <Box mt={3}>
            <LoadingAnimation title="Loading platform health" />
          </Box>
        ) : (
          <Box m={2}>
            {selectedServices?.length === 0 && (
              <>

                <Box mt={2}>
                  <Box>
                    <Grid container spacing={2}>
                      {servicesColumns.map((column) => (
                        <Grid item xs={12} sm={6} md={4} key={column[0]?.service || Math.random()}>
                          {column.map((service) => (
                            <Box key={service?.service} display="flex" alignItems="center">
                              {getIcon(service?.health)}
                              <Typography style={{ marginLeft: '8px' }}>{service?.service}</Typography>
                              <IconButton onClick={(e) => handleOpenServiceDetail(service?.service)}>
                                <SearchIcon />
                              </IconButton>
                            </Box>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Box mr={2}>
                      <Tooltip title="Press this button only if there are relevant fixes performed. It might take several minutes " arrow>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.applyButton}
                          onClick={handleTriggerTestsExecutions}
                          id="btnTriggerTests"
                          style={{ alignSelf: 'center' }}
                          disabled={triggerTestsExecutions.isLoading}
                        >
                          Trigger Tests Executions
                        </Button>
                      </Tooltip>
                    </Box>

                    <Box mr={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.applyButton}
                        onClick={handleTriggerHealthCalculation}
                        disabled={triggerHealthCalculation.isLoading}
                        id="btnTriggerTests"
                        style={{ alignSelf: 'center' }}
                      >
                        Recalculate Health
                      </Button>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" m={2} mt={2}>
                    <Typography>
                      {localStorage.getItem('lastTestExecution') && (
                        <Typography>
                          ⚙️ e2e tests triggered manually on
                          {' '}
                          {
                            moment(localStorage.getItem('lastTestExecution'))?.format('DD MMM [at] HH:mm')
                          }
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            {selectedServices?.length === 1 && (
              <>
                <Box mt={2}>
                  <Typography
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={handleBackToAllServices}
                  >
                    {'< Back to all services'}
                  </Typography>
                </Box>
                <Box display="flex" mt={2}>
                  <Box justifyContent="center" alignContent="center" mr={2}>
                    <Typography>Health on </Typography>
                  </Box>
                  <Select
                    value={selectedDate || selectorDates[selectorDates.length - 1]}
                    onChange={(e) => {
                      const { value } = e.target;
                      setSelectedDate(value);
                    }}
                    className={classes.select}
                  >
                    {selectorDates?.map((dateItem) => (
                      <MenuItem key={dateItem} value={dateItem}>
                        {moment(dateItem).format('DD-MM-YYYY HH:mm')}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    {renderAlarmFailureDetailsCount() > 0 && (
                      <Grid item xs={12} sm={6} md={6}>
                        <Box mt={4}>
                          <Box mb={2}>
                            <Typography
                              variant="h2"
                            >
                              Alarms
                            </Typography>
                          </Box>
                          {renderAlarmFailureDetails()}
                        </Box>
                      </Grid>
                    )}

                    {renderTestFailureDetailsCount() > 0 && (
                      <Grid item xs={12} sm={6} md={6}>
                        <Box mt={4}>
                          <Box mb={2}>
                            <Typography
                              variant="h2"
                            >
                              Tests
                            </Typography>
                          </Box>
                          {renderTestFailureDetails()}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box
                  mb={2}
                  mt={4}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="h2">
                    Health Evolution
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Box mr={2}>
                      <Typography>
                        From
                        {' '}
                        {labels[0] || ''}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        To
                        {' '}
                        {labels[labels.length - 1] || ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.chartContainer}>
                  <Line
                    data={chartData}
                    options={{
                      ...options,

                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        )
      }
    </Box>
  );
}
