import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import SubjectIcon from '@material-ui/icons/Subject';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import * as XLSX from 'xlsx';
import moment from 'moment';
import MessageCard from '../../components/utils/MessageCard';
import GraphqlService from '../../service/graphqlService';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { StyledTableCell, StyledTableRow, StyledTableHeaderCell } from '../../components/utils/StyledTable';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
    padding: 16,
  },
  button: {
    float: 'right',
    margin: '0 0 16px 24px',
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  tableIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  dateField: {
    marginRight: 16,
    width: 200,
  },
  searchButton: {
    height: 56,
    borderRadius: 12,
  },
  titleCount: {
    fontWeight: 'bold',
    marginLeft: 8,
  },
}));

export default function NewUsersList() {
  const classes = useStyles();
  const [from, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [to, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [searchTriggered, setSearchTriggered] = useState(false);

  const {
    data: usersData,
    error: errorFetching,
    isLoading,
    refetch,
  } = useQuery(['newUsers', from, to],
    async () => GraphqlService.getNewUsersBetweenDates(
      // send as date
      moment(from),
      moment(to),
    ), {
      enabled: searchTriggered,
      cacheTime: 300000, // Cache for 5 minutes
      staleTime: 300000, // Data is considered stale after 5 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    });

  const handleSearch = () => {
    setSearchTriggered(true);
    refetch();
  };

  const downloadCsv = () => {
    if (!usersData || !usersData.length) return;

    let csvContent = 'User ID,First Action Date,Email,Cognito Group\n';
    usersData.forEach((user) => {
      const row = `${user.username},${user.createdDate},${user.email},"${user.groups}"`;
      csvContent += `${row}\n`;
    });

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'NewUsersList.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const downloadXls = () => {
    if (!usersData || !usersData.length) return;

    const worksheet = XLSX.utils.json_to_sheet(usersData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    const xlsBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    const blob = new Blob([xlsBuffer], { type: 'application/vnd.ms-excel' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'NewUsersList.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyUsersInClipboard = () => {
    if (!usersData) return;
    const emails = usersData.map((user) => user.email).join(';');
    navigator.clipboard.writeText(emails);
  };

  function renderContent() {
    if (errorFetching) {
      return <MessageCard message={`Error: ${errorFetching.message}`} />;
    }

    if (isLoading && searchTriggered) {
      return (
        <>
          <ProgressDialog open header="Retrieving new users, please wait" />
          <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
        </>
      );
    }

    if (!searchTriggered) {
      return <MessageCard message="Select a date range and click Search to view new users" />;
    }

    if (usersData?.length > 0) {
      return (
        <>
          <Grid container direction="column" spacing={2}>
            <div className={classes.tableIcons}>
              <Button
                id="btnCSV"
                type="form"
                startIcon={<GetAppIcon />}
                className={classes.tableButton}
                color="secondary"
                variant="contained"
                onClick={downloadCsv}
              >
                CSV
              </Button>
              <Button
                id="btnXLS"
                type="form"
                startIcon={<SubjectIcon />}
                className={classes.tableButton}
                color="secondary"
                variant="contained"
                onClick={downloadXls}
              >
                XLS
              </Button>
              <Button
                id="btnCopyEmails"
                type="form"
                startIcon={<AssignmentTurnedInIcon />}
                className={classes.tableButton}
                color="secondary"
                variant="contained"
                onClick={copyUsersInClipboard}
              >
                CLIPBOARD EMAILS
              </Button>
            </div>

            <TableContainer component={Paper} className={classes.mainTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell>User ID</StyledTableHeaderCell>
                    <StyledTableHeaderCell>First Action Date</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Email</StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData.map((user) => (
                    <StyledTableRow key={user.username}>
                      <StyledTableCell>{user.username}</StyledTableCell>
                      <StyledTableCell>{user.createdDate}</StyledTableCell>
                      <StyledTableCell>{user.email}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      );
    }

    return <MessageCard message="No new users found for the selected date range" />;
  }

  const titleWithCount = usersData?.length > 0
    ? `${usersData.length} NEW USERS `
    : '';

  return (
    <Box>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={{
          fontWeight: 'bold',
          margin: '16px 0',
          padding: '0 16px',
        }}
      >
        {titleWithCount}
      </Typography>
      <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">
        <div className={classes.dateContainer}>
          <TextField
            id="start-date"
            label="Start Date"
            type="date"
            value={from}
            onChange={(e) => setStartDate(e.target.value)}
            className={classes.dateField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="End Date"
            type="date"
            value={to}
            onChange={(e) => setEndDate(e.target.value)}
            className={classes.dateField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            className={classes.searchButton}
          >
            Search
          </Button>
        </div>
        <div>
          {renderContent()}
        </div>
      </Grid>
    </Box>
  );
}
