/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import {
  Grid, Box, makeStyles,
  Button, TextField,
  LinearProgress, CircularProgress,
  FormControlLabel, Switch, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ReactJson from 'react-json-view';
import {
  React, useState, useEffect,
} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useQuery } from '@tanstack/react-query';
import PageBanner from '../../../components/utils/PageBanner';
import { stringHasValue } from '../../../utils/stringHasValue';
import GraphqlService from '../../../service/graphqlService';
import { useProject } from '../../../config/ProjectContext';
import FineGrainedPermissionsInfo from '../../Admin/PlatformHealth/FineGrainedPermissions/FineGrainedPermissionsInfo';
import useIsDevicePermissionAllowed from '../../../hooks/useIsDevicePermissionAllowed';
import SimpleAlert from '../../../components/utils/SimpleAlert';
import BrandEvents from './BrandEvents';
import DeviceInfo from './DeviceInfo';
import BrandConsumptions from './BrandConsumptions';
import DeviceSettings from './DeviceSettings';
import DeviceInfoTab from './DeviceInfoTab';
import DeploymentEventsHistory from './DeploymentEventsHistory';
import ModuleSettings from './ModuleSettings';
import cleanAllNullValues from '../../../utils/cleanAllNullValues';
import DateRangePicker from '../../../components/utils/DateRangePicker';

import {
  SHOW_TELEMETRY_EVENTS_PERMISSION,
  SHOW_BRAND_CONSUMPTIONS_PERMISSION,
} from '../../../utils/constants';
import Restricted from '../../../authorization/Restricted';
import ConnectivityStatusHistory from './ConnectivityStatusHistory';

export default function CertificateStatus() {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
    },
    separate: {
      padding: 24,
    },
    container: {
      border: '1px solid #ccc',
      borderRadius: '8px',
      padding: '16px',
      margin: '24px',
    },
    searchButton: {
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
      '&:disabled': {
        color: theme.palette.primary.light,
      },
    },
    clearButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
    },
    jsonButton: {
      bottom: '16px',
      right: '16px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
    },
    greenTab: {
      backgroundColor: '#c2fcc2',
      color: 'black',
      width: 150,
    },
    redTab: {
      backgroundColor: '#f5a5a5',
      color: 'black',
      width: 150,
    },
    details: {
      backgroundColor: theme.palette.common.white,
    },
    machineInfoSubSection: {

    },
    machineInfoSubSection12: {

    },
    machineInfoCardTitle: {

    },
    machineInfoCardFontSize16: {
      fontSize: '16px',
    },
    machineInfoCardSectionContent: {

    },
    machineInfoCardPaddingLeft16: {
      paddingLeft: '16px',
    },
    machineInfoCardExtraField: {

    },
    machineInfoCardFontSize14: {
      fontSize: '14px',
    },
    machineInfoCardSectionContentModules: {

    },
    machineInfoCardPaddingLeft16NoTop: {
      paddingLeft: '16px',
      paddingTop: '0px',
    },
    detailsTitle: {

    },
    toggleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
    },
    noResultsMessage: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    retryButtons: {
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }));

  const [deviceSerial, setDeviceSerial] = useState();
  const [machineInfo, setMachineInfo] = useState(null);
  const [isLoadingMachineStatus, setIsLoadingMachineStatus] = useState(false);
  const [isLoadingConnectivityStatusHistory, setIsLoadingConnectivityStatusHistory] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [isValidDevice, setIsValidDevice] = useState(false);
  const [connectivityStatus, setConnectivityStatus] = useState(null);
  const [connectivityStatusHistoryToken, setConnectivityStatusHistoryToken] = useState('');
  const [isLoadingConnectivityStatus, setIsLoadingConnectivityStatus] = useState(false);
  const { isAllowed } = useIsDevicePermissionAllowed(deviceSerial);
  const [shadowState, setShadowState] = useState(null);
  const [isLoadingShadowState, setIsLoadingShadowState] = useState(false);
  const [useProcessedTimestamp, setUseProcessedTimestamp] = useState(false);
  const [noResultsDialogOpen, setNoResultsDialogOpen] = useState(false);
  const [selectedTimeRangeDays, setSelectedTimeRangeDays] = useState(0);
  const [triggerEventsSearch, setTriggerEventsSearch] = useState(false);
  const [triggerConsumptionsSearch, setTriggerConsumptionsSearch] = useState(false);

  const { project } = useProject();

  useEffect(() => {
    if (isAllowed) {
      setIsValidDevice(true);
    } else {
      setIsValidDevice(false);
    }
  }, [isAllowed]);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  const classes = useStyles();

  const { refetch: fetchMachineInfo } = useQuery(
    ['machineTelemetryConsumption', project?.code, deviceSerial],
    async () => {
      const consumptionResponse = await GraphqlService.getMachineTelemetryConsumption(
        project.code, deviceSerial, undefined, undefined, 10, 0,
      );
      return consumptionResponse?.machineInfo;
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setMachineInfo(data);
        setIsLoadingMachineStatus(false);
      },
    },
  );

  const [connectivityStatusHistory, setConnectivityStatusHistory] = useState([]);

  const [selectedStartDate, setSelectedStartDate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return moment(date);
  });
  const [selectedEndDate, setSelectedEndDate] = useState(moment(new Date()));
  const [selectedStartTime, setSelectedStartTime] = useState('00:00');
  const [selectedEndTime, setSelectedEndTime] = useState('23:59');

  const { refetch: fetchDeviceConnectivityStatusHistory } = useQuery(
    ['deviceConnectivityStatusHistory', project?.code, deviceSerial, selectedStartDate, selectedEndDate],
    async () => GraphqlService.getDeviceConnectivityStatusHistory(
      {
        projectId: project.code,
        serialNumber: deviceSerial,
        from: selectedStartDate.toISOString(),
        to: selectedEndDate.toISOString(),
        paginationToken: connectivityStatusHistoryToken || '',
      },
    ),
    {
      enabled: false, // Disable automatic fetching
      onSuccess: (data) => {
        setConnectivityStatusHistory(data?.events || []);
        setConnectivityStatusHistoryToken(data?.nextToken || '');
        setIsLoadingConnectivityStatusHistory(false);
      },
    },
  );

  const { refetch: fetchDeviceConnectivityStatus } = useQuery(
    ['deviceConnectivityStatus', project?.code, deviceSerial],
    async () => GraphqlService.getDeviceConnectivityStatus(
      {
        projectId: project.code,
        serialNumber: deviceSerial,
      },
    ),
    {
      enabled: false, // Disable automatic fetching
      onSuccess: (data) => {
        setConnectivityStatus(data);
        setIsLoadingConnectivityStatus(false);
      },
    },
  );

  const { refetch: fetchShadowState } = useQuery(
    ['getShadowState', project?.code, deviceSerial],
    async () => GraphqlService.getShadowState(
      { projectId: project.code, serialNumber: deviceSerial },
    ),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data?.reported?.machineInfo) {
          try {
            setShadowState({
              ...data,
              reported: {
                ...data.reported,
                machineInfo: data?.reported?.machineInfo ? JSON.parse(data.reported.machineInfo) : {},
              },
            });
          } catch (error) {
            console.error('Error parsing machineInfo JSON:', error);
            setShadowState(data);
          }
        } else {
          setShadowState(data);
        }
        setIsLoadingShadowState(false);
      },
    },
  );

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  const handleSearchDeviceSerial = () => {
    if (!isValidDevice) {
      openAlert('Error', 'Device does not have the required permissions.');
      return null;
    }
    fetchMachineInfo();
    fetchDeviceConnectivityStatus();
    setIsLoadingMachineStatus(true);
    setIsLoadingConnectivityStatus(true);
    return true;
  };

  const handleSearchConnectivityStatusHistory = () => {
    if (!isValidDevice) {
      openAlert('Error', 'Device does not have the required permissions.');
      return null;
    }
    fetchDeviceConnectivityStatusHistory();
    setIsLoadingConnectivityStatusHistory(true);
    return true;
  };

  const handleClear = () => {
    setDeviceSerial('');
    setMachineInfo(null);
    setIsLoadingMachineStatus(false);
    setIsLoadingConnectivityStatusHistory(false);
    setTabValue('1');
    setUseProcessedTimestamp(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    // Automatically trigger search when switching to the Connectivity Status History tab
    if (newValue === '2' && deviceSerial) {
      handleSearchConnectivityStatusHistory();
    }
  };

  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      // Moving to the next page
      setPage(newPage);
      setPaginationTokens([...paginationTokens, connectivityStatusHistoryToken]);
    } else if (newPage < page) {
      // Moving to the previous page
      setPage(newPage);
      setConnectivityStatusHistoryToken(paginationTokens[newPage] || '');
    }
  };

  // Helper function to check if a valid device serial exists
  const isDeviceSerialValid = () => stringHasValue(deviceSerial);

  useEffect(() => {
    if (connectivityStatusHistoryToken) {
      fetchDeviceConnectivityStatusHistory();
    }
  }, [connectivityStatusHistoryToken]);

  const handleDateChange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const [selectedComponent, setSelectedComponent] = useState('Events');

  const handleComponentChange = (event) => {
    setSelectedComponent(event.target.value);

    // Automatically trigger search when Consumptions is selected
    if (event.target.value === 'Consumptions' && deviceSerial) {
      setTriggerConsumptionsSearch(!triggerConsumptionsSearch);
    }
  };

  const handleClickShadowState = () => {
    setTabValue('3');
    if (!isValidDevice) {
      openAlert('Error', 'Device does not have the required permissions.');
      return null;
    }
    fetchShadowState();
    setIsLoadingShadowState(true);
    return true;
  };

  useEffect(() => {
    handleClear();
  }, [project]);

  const changeTab = (tab) => {
    if (!isValidDevice) {
      openAlert('Error', 'Device does not have the required permissions.');
      setTabValue('1');
      return null;
    }
    setTabValue(tab);
    return true;
  };

  const handleTimestampToggleChange = (event) => {
    setUseProcessedTimestamp(event.target.checked);
  };

  const handleNoResultsDialogClose = () => {
    setNoResultsDialogOpen(false);
  };

  const handleSwitchToProcessedTimestamp = () => {
    setUseProcessedTimestamp(true);
    setNoResultsDialogOpen(false);

    // If time range is more than 3 days, adjust the start date
    if (selectedTimeRangeDays > 3) {
      const newStartDate = new Date(selectedEndDate);
      newStartDate.setDate(newStartDate.getDate() - 3);
      setSelectedStartDate(newStartDate);
    }

    if (selectedComponent === 'Events') {
      setTriggerEventsSearch(!triggerEventsSearch);
    }

    if (selectedComponent === 'Consumptions') {
      setTriggerConsumptionsSearch(!triggerConsumptionsSearch);
    }
  };

  // Function to notify no results found - to be passed to child components
  const notifyNoResults = (startDate, endDate) => {
    // Calculate days between dates
    if (!startDate || !endDate) return;

    try {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const differenceInTime = end.getTime() - start.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      setSelectedTimeRangeDays(differenceInDays);
      setNoResultsDialogOpen(true);
    } catch (error) {
      console.error('Error calculating date difference:', error);
    }
  };

  return (
    <Box id="device-overview" className={classes.root}>
      <PageBanner title="DEVICE OVERVIEW" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />

      <FineGrainedPermissionsInfo
        open={false}
        onClose={() => { }}
        style={{ margin: 24 }}
      />

      {/* Add the Dialog for No Results */}
      <Dialog
        open={noResultsDialogOpen}
        onClose={handleNoResultsDialogClose}
        aria-labelledby="no-results-dialog-title"
        aria-describedby="no-results-dialog-description"
      >
        <DialogTitle id="no-results-dialog-title">No Results Found</DialogTitle>
        <DialogContent>
          <DialogContentText id="no-results-dialog-description">
            Your search was conducted using the machine&apos;s timestamp (created_on).
            Please note that there may be instances where this timestamp is incorrectly set,
            which could affect the search results.
          </DialogContentText>
          <DialogContentText style={{ marginTop: 8 }}>
            Would you like to perform the search using the processed timestamp (processed_on) instead?
            This will automatically adjust your search to the last 3 days.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.retryButtons}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSwitchToProcessedTimestamp}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={handleNoResultsDialogClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid item xs={12} className={classes.separate}>
          <Box style={{ marginBottom: 28 }}>
            <Typography>
              Check your device&apos;s information and activity.
            </Typography>
          </Box>

          <Grid container>
            <Grid item md={6} xs={12}>
              <Grid container className={classes.filters} spacing={2}>
                <Grid item sm="auto">
                  <TextField
                    id="device-serial"
                    value={deviceSerial || ''}
                    onChange={(e) => setDeviceSerial(e.target.value)}
                    label="Device Serial"
                    variant="outlined"
                    style={{ minWidth: 300 }}
                  />
                </Grid>
                <Grid item sm="auto" style={{ alignSelf: 'center' }}>
                  <Button
                    id="btn-search"
                    onClick={handleSearchDeviceSerial}
                    className={classes.searchButton}
                    variant="contained"
                    color="secondary"
                    disabled={!stringHasValue(deviceSerial)}
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item sm="auto" style={{ alignSelf: 'center' }}>
                  <Button
                    id="btnClear"
                    onClick={handleClear}
                    className={classes.clearButton}
                    variant="contained"
                    startIcon={<RefreshIcon />}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              {(isLoadingConnectivityStatus || isLoadingMachineStatus) ? (
                <CircularProgress />
              ) : (
                <Grid container className={classes.filters} spacing={2}>
                  {connectivityStatus !== null && (
                    <Box>
                      <Box display="flex" alignItems="center">
                        {connectivityStatus.connected ? (
                          <CheckCircleIcon style={{ color: 'green', marginRight: 8 }} />
                        ) : (
                          <CancelIcon style={{ color: 'red', marginRight: 8 }} />
                        )}
                        {connectivityStatus.connected ? (
                          <Typography> Connected </Typography>
                        ) : (
                          <Typography> Disconnected </Typography>
                        )}
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography style={{ fontWeight: 'bold' }}> Last updated: </Typography>
                        <Typography ml={2}>
                          {connectivityStatus?.lastUpdate ? moment(connectivityStatus.lastUpdate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
                        </Typography>
                      </Box>
                      {
                        connectivityStatus?.disconnectReason && (
                          <Box display="flex" alignItems="center">
                            <Typography style={{ fontWeight: 'bold' }}> Reason: </Typography>
                            <Typography ml={2}>
                              {connectivityStatus.disconnectReason}
                            </Typography>
                          </Box>
                        )
                      }
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange} aria-label="device overview tabs">
          <Tab label="Device Info" value="1" onClick={() => changeTab('1')} />
          <Tab
            label="Connectivity Status History"
            value="2"
            onClick={() => changeTab('2')}
            disabled={!isDeviceSerialValid()}
          />
          <Tab
            label="Shadow Wrapper"
            value="3"
            onClick={handleClickShadowState}
            disabled={!isDeviceSerialValid()}
          />
          <Tab
            label="Device Telemetry"
            value="4"
            onClick={() => changeTab('4')}
            disabled={!isDeviceSerialValid()}
          />
          <Tab
            label="Deployment History"
            value="5"
            onClick={() => changeTab('5')}
            disabled={!isDeviceSerialValid()}
          />
        </TabList>

        <TabPanel value="1" className={classes.details}>
          <DeviceInfoTab machineInfo={machineInfo} isLoading={isLoadingMachineStatus} />
        </TabPanel>

        <TabPanel value="2">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <DateRangePicker
              onDateChange={handleDateChange}
              setSelectedStartDate={setSelectedStartDate}
              selectedStartDate={selectedStartDate}
              setSelectedStartTime={setSelectedStartTime}
              selectedStartTime={selectedStartTime}
              setSelectedEndDate={setSelectedEndDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndTime={setSelectedEndTime}
              selectedEndTime={selectedEndTime}
              onApply={handleSearchConnectivityStatusHistory} // Add this prop
            />
            <Button
              onClick={handleSearchConnectivityStatusHistory}
              className={classes.searchButton}
              variant="contained"
              color="secondary"
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </Box>
          {isLoadingConnectivityStatusHistory
            ? <LinearProgress />
            : (
              <ConnectivityStatusHistory
                data={connectivityStatusHistory || []}
                isLoading={isLoadingConnectivityStatusHistory}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                count={connectivityStatusHistory?.length || 0}
                connectivityStatusHistoryToken={connectivityStatusHistoryToken}
              />
            )}
        </TabPanel>

        <TabPanel value="3">
          <Grid item xs={12} className={classes.reactJsonContainer}>
            {isLoadingShadowState ? <LinearProgress />
              : (
                <ReactJson
                  src={cleanAllNullValues(shadowState || {})}
                  theme="rjv-default"
                  collapsed={false}
                  displayDataTypes={false}
                  enableClipboard
                  style={{ fontSize: '1rem', lineHeight: '1.5' }}
                />
              )}
          </Grid>
        </TabPanel>

        <TabPanel value="4">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <TextField
              select
              label="Select Component"
              value={selectedComponent}
              onChange={handleComponentChange}
              variant="outlined"
              SelectProps={{
                native: true,
              }}
            >
              <option value="Events">Events</option>
              <option value="DeviceInfo">Device Info</option>
              <option value="Consumptions">Consumptions</option>
              <option value="Settings">Settings</option>
              <option value="ModuleSettings">Module Settings</option>
            </TextField>

            { (selectedComponent === 'Events' || selectedComponent === 'Consumptions') && (
            <Box className={classes.toggleContainer}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={useProcessedTimestamp}
                    onChange={handleTimestampToggleChange}
                    color="primary"
                  />
                )}
                label="Use processed timestamp"
              />
            </Box>
            )}
          </Box>
          {selectedComponent === 'Events' && (
            <Restricted to={SHOW_TELEMETRY_EVENTS_PERMISSION}>
              <BrandEvents
                deviceSerial={deviceSerial}
                useProcessedTimestamp={useProcessedTimestamp}
                onNoResults={notifyNoResults}
                triggerEventsSearch={triggerEventsSearch}
              />
            </Restricted>
          )}
          {selectedComponent === 'DeviceInfo' && (
            <Restricted to={SHOW_TELEMETRY_EVENTS_PERMISSION}>
              <DeviceInfo
                deviceSerial={deviceSerial}
                useProcessedTimestamp={useProcessedTimestamp}
                onNoResults={notifyNoResults}
              />
            </Restricted>
          )}
          {selectedComponent === 'Consumptions' && (
            <Restricted to={SHOW_BRAND_CONSUMPTIONS_PERMISSION}>
              <BrandConsumptions
                deviceSerial={deviceSerial}
                useProcessedTimestamp={useProcessedTimestamp}
                onNoResults={notifyNoResults}
                triggerConsumptionsSearch={triggerConsumptionsSearch}
              />
            </Restricted>
          )}
          {selectedComponent === 'Settings' && (
            <Restricted to={SHOW_TELEMETRY_EVENTS_PERMISSION}>
              <DeviceSettings
                deviceSerial={deviceSerial}
                useProcessedTimestamp={useProcessedTimestamp}
                onNoResults={notifyNoResults}
              />
            </Restricted>
          )}
          {selectedComponent === 'ModuleSettings' && (
            <Restricted to={SHOW_TELEMETRY_EVENTS_PERMISSION}>
              <ModuleSettings
                deviceSerial={deviceSerial}
                useProcessedTimestamp={useProcessedTimestamp}
                onNoResults={notifyNoResults}
              />
            </Restricted>
          )}
        </TabPanel>

        <TabPanel value="5">
          <DeploymentEventsHistory deviceSerial={deviceSerial} />
        </TabPanel>
      </TabContext>

    </Box>
  );
}
